import isEmpty from 'lodash/isEmpty'
import { range, titleCaps } from '../universal'

export const convertToSlug = (str) => {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaaeeeeiiiioooouuuunc------'

  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

export const formattedAddress = (address) => {
  return `${address?.street_name} ${address?.external_number}, ${address?.state_name} ${address?.city_name}`
}

export const isAstrollantas = (value) => {
  if (value) {
    return value.toLowerCase().includes('astrollantas')
  }
  return false
}

export const formatTypesName = (nameProp) => {
  const name = nameProp?.toLowerCase() ?? ''
  if (name === 'sedan 4 puertas') {
    return titleCaps(name.replace(' 4 puertas', ''))
  }
  if (name.toLowerCase() === 'camioneta suv') {
    return titleCaps(name.replace('camioneta ', ''))
  }
  if (name.toLowerCase() === 'coupe 2 puertas') {
    return titleCaps(name.replace(' 2 puertas', ''))
  }
  return titleCaps(name)
}

export const formatStateToShortName = (name) => {
  if (name === 'Estado de México' || name === 'Estado de Mexico') {
    name = 'EDO. MEX.'
  }
  if (name === 'Ciudad de México' || name === 'Ciudad de Mexico') {
    name = 'CDMX'
  }
  return name
}

export const formatAllFilters = (nameProp) => {
  const name = nameProp?.toLowerCase() ?? ''
  if (name === 'sedan 4 puertas') {
    return titleCaps(name.replace(' 4 puertas', ''))
  }
  if (name.toLowerCase() === 'camioneta suv') {
    return titleCaps(name.replace('camioneta ', ''))
  }
  if (name.toLowerCase() === 'coupe 2 puertas') {
    return titleCaps(name.replace(' 2 puertas', ''))
  }
  if (name.toLowerCase() === 'Hatchback') {
    return 'Hatchback'
  }
  if (nameProp.includes('Estado De México') || nameProp.includes('Ciudad de México, Estado de México, CDMX')) {
    return 'EDO. MEX.'
  }
  if (nameProp === 'Ciudad De México' || nameProp === 'Ciudad de Mexico') {
    return 'CDMX'
  }
  if (nameProp.includes('Michoacán')) {
    return 'Michoacán'
  }
  if (nameProp.includes('true')) {
    return 'Easy'
  }

  if (nameProp?.toLowerCase().includes('garantiatriple')) {
    return 'Garantizados'
  }

  if (nameProp?.toLowerCase().includes('entregainmediata')) {
    return 'Entrega inmediata'
  }

  if (nameProp?.toLowerCase().includes('descuentos')) {
    return 'Descuentos'
  }

  if (nameProp.includes('discountAmount:desc')) {
    return 'Descuento'
  }
  if (nameProp.includes('price:asc')) {
    return 'Menor precio'
  }
  if (nameProp === '32' || nameProp === '12') {
    return 'Edo. Mex. - Lomas Verdes'
  }

  if (nameProp === '1' || nameProp === '4') {
    return 'Edo. Mex. - Satélite'
  }

  if (nameProp === '33' || nameProp === '11') {
    return 'CDMX - Gran Sur'
  }

  if (nameProp === '7' || nameProp === '10') {
    return 'CDMX - Nápoles'
  }

  if (nameProp === '35' || nameProp === '13') {
    return 'Querétaro - Juriquilla'
  }

  if (nameProp === '36' || nameProp === '14') {
    return 'Qro. - La Capilla'
  }

  return titleCaps(nameProp)
}

export const getRandomPeopleRequestingCredit = () => Math.floor(
  Math.random() * (2) + 1, // (10 - 3 + 1) + 3
)

// (20 - 5 + 1) + 5
export const getRandomPeopleWatchingCard = () => Math.floor(Math.random() * (13 - 3 + 1) + 3)

export const updateFromToYear = (from, to) => {
  let rangeArr = []
  if (from === to) {
    rangeArr.push(from)
  } else {
    rangeArr = range(from, to)
  }
  return rangeArr.length > 0 ? rangeArr.join('_') : ''
}

export const mapType = (bodiesProp = []) => {
  const bodiesMapped = bodiesProp.map((bodie) => {
    return {
      ...bodie,
      text: formatTypesName(bodie.name),
    }
  })
  return {
    bodies: bodiesMapped,
  }
}

export const formatStateName = (name) => {
  if (name === 'CDMX / Edo Mex') {
    name = 'Ciudad de México, Estado de México, CDMX'
  }
  if (name === 'EDO MEX ClikAuto Lomas Verdes') {
    name = 'Edo. Méx. ClikAuto Lomas Verdes'
  }
  if (name === 'Estado de México ClikAuto Satélite') {
    name = 'Edo. Méx. ClikAuto Satélite'
  }
  if (name === 'Ciudad de México ClikAuto Nápoles') {
    name = 'CDMX ClikAuto Nápoles'
  }
  if (name === 'Michoacán') {
    name = 'Michoacán,Michoacan'
  }
  return name
}

export const formatSellerName = (name) => {
  if (`${name}`.toLowerCase().includes('lomas verdes')) {
    name = 'Edo. Méx. - Lomas Verdes'
  }
  if (`${name}`.toLowerCase().includes('satélite') || `${name}`.toLowerCase().includes('satelite')) {
    name = 'Edo. Méx. - Satélite'
  }

  if (`${name}`.toLowerCase().includes('gran sur')) {
    name = 'CDMX - Gran Sur'
  }
  if (`${name}`.toLowerCase().includes('nápoles') || `${name}`.toLowerCase().includes('napoles')) {
    name = 'CDMX - Nápoles'
  }
  return name
}

export const getAddonTitle = (addon, type = 'title') => {
  const keys = {
    warranty: {
      title: 'Garantía extendida',
      subtitle: 'Cubertura: Elementos mecánicos, eléctricos o electrónicos.',
      sumary: 'Garantía mecánica. Cubre los costos relacionados a la reparación o sustitución de piezas o componentes que presenten defectos como consecuencia de una avería fortuita en los elementos mecánicos, eléctricos o electrónicos.',
      disclaimer: 'Para hacer válida la garantía es necesaria una inspección profunda del auto para realizar diagnóstico y conocer situación del vehículo.',
    },
    maintenance: {
      title: 'Matenimientos 6 meses',
      subtitle: 'Incluye: Mantenimientos que el auto necesita para su buen funcionamiento. ',
    },
    tire_replacement: {
      title: 'Respuesto de llantas',
      subtitle: 'Incluye: Repuesto de llantas a causa de algún accidente o banquetazo. ',
    },
  }
  if (type === 'title') return keys[addon?.title]?.title || addon?.title
  if (type === 'subtitle') return addon.subtitle || keys[addon?.title]?.subtitle
  if (type === 'sumary') return addon.sumary || keys[addon?.title]?.sumary
  if (type === 'disclaimer') return addon.disclaimer || keys[addon?.title]?.disclaimer
}

export const getTouchedStatus = (fieldState, clicked = false, anotherField = null) => {
  if (fieldState.invalid && fieldState.isDirty) {
    return false
  }
  if (fieldState.isTouched || clicked || !isEmpty(anotherField)) {
    return true
  }
  return false
}

export const getErrorEmptyStatus = (fieldState, field) => {
  if (fieldState.invalid && field?.length === 0) {
    return true
  }
  return false
}

const replaceSpaces = (text) => {
  return text.replace(/ /g, '%20')
}

// TOdo: verificar names tolowercase
export const getType = (body) => {
  return `/autos-seminuevos/tipo-${body.name.replace(/ /g, '%20')}`
}

export const getLink = (make = '', model = '', year = '') => {
  if (make && model && year) {
    return `/autos-seminuevos/marca-${replaceSpaces(make.name).toLowerCase()}/modelo-${replaceSpaces(model.name).toLowerCase()}/antiguedad-${year}`
  }
  if (make && model) {
    return `/autos-seminuevos/marca-${replaceSpaces(make.name).toLowerCase()}/modelo-${replaceSpaces(model.name).toLowerCase()}`
  }
  return `/autos-seminuevos/marca-${make.name.replace(/ /g, '%20').toLowerCase()}`
}

export const getBranch = (branches, branchId) => {
  return branches?.find((b) => b.branch_id === branchId)
}

export const getMapLinkByBranchId = (branchesLinks, branchId) => {
  let branchLink
  if (branchId === 7 || branchId === 10) { // Napoles
    // eslint-disable-next-line prefer-destructuring
    branchLink = branchesLinks[0]
  }
  if (branchId === 1 || branchId === 4) { // Satelite
    // eslint-disable-next-line prefer-destructuring
    branchLink = branchesLinks[1]
  }
  if (branchId === 33 || branchId === 11) { // Gran sur
    // eslint-disable-next-line prefer-destructuring
    branchLink = branchesLinks[2]
  }
  if (branchId === 32 || branchId === 12) { // Lomas verde
    // eslint-disable-next-line prefer-destructuring
    branchLink = branchesLinks[3]
  }
  if (branchId === 35 || branchId === 13) { // Juriquilla
    // eslint-disable-next-line prefer-destructuring
    branchLink = branchesLinks[4]
  }
  if (branchId === 36 || branchId === 14) { // a capilla
    // eslint-disable-next-line prefer-destructuring
    branchLink = branchesLinks[5]
  }
  return branchLink
}

export function formatYears(years) {
  const yearArray = years.split('_')
  const firstYear = yearArray[0]
  const lastYear = yearArray[yearArray.length - 1]
  return `${firstYear}-${lastYear}`
}

export const addDaysToSpecificDate = (today, daysToAdd = 5) => {
  const date = new Date(today)
  date.setDate(date.getDate() + daysToAdd)
  const options = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
  }
  const formattedDate = date.toLocaleDateString('es-ES', options)
  return formattedDate
}

export const formatDateString = (dateString) => {
  // Crear un objeto Date a partir del string de fecha
  const date = new Date(dateString)

  // Obtener los componentes de la fecha
  const day = date.getDate()
  const year = date.getFullYear()

  // Obtener el nombre del mes
  const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
  const month = monthNames[date.getMonth()]

  // Obtener formato 12 horas
  let hours = date.getHours()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours %= 12
  hours = hours || 12 // El '0' debe ser '12'

  // Construir el string final en el formato deseado
  const formattedDate = `${day} · ${month} · ${year} · ${hours}${ampm}`

  return formattedDate
}

export const textCapitalize = (string) => {
  if (!string) {
    return string
  }
  const parts = string?.split(' ')
  return parts
    .map((s) => s.charAt(0).toUpperCase() + s.toLowerCase().slice(1))
    .join(' ')
}

export const findCustomerFirstName = (name) => {
  const primerEspacioIndex = name?.indexOf(' ')
  if (primerEspacioIndex !== -1) {
    return name?.slice(0, primerEspacioIndex)
  }
  return name
}

export const getLocationTitleFormatted = (location) => {
  let formattedLocation = ''

  switch (location) {
    case 'CDMX Gran Sur':
      formattedLocation = 'ClikAuto Gran Sur · CDMX'
      break
    case 'CDMX Napoles':
      formattedLocation = 'ClikAuto Napoles · CDMX'
      break
    case 'EDO MEX Lomas Verdes':
      formattedLocation = 'ClikAuto Lomas Verdes · EDO MEX'
      break
    case 'EDO MEX Satélite':
      formattedLocation = 'ClikAuto Satélite · EDO MEX'
      break
    case 'QRO Juriquilla':
      formattedLocation = 'ClikAuto Juriquilla · QRO'
      break
    default:
      formattedLocation = location
      break
  }

  return formattedLocation
}

export const convertTo12HourFormat = (timeString) => {
  const [hours, minutes] = timeString?.split(':')?.map(Number)
  const meridiem = hours >= 12 ? 'PM' : 'AM'
  const twelveHourFormatHours = hours % 12 || 12
  return `${twelveHourFormatHours}:${minutes < 10 ? '0' : ''}${minutes} ${meridiem}`
}

export const scrollTo = (elementId, position = 0, isMinusOperation = true) => {
  if (typeof window !== 'undefined') {
    const element = document.getElementById(elementId)
    if (element) {
      setTimeout(() => {
        const top = isMinusOperation ? element.offsetTop - position : element.offsetTop + position
        window.scrollTo({ top, behavior: 'smooth' })
      }, 100) // 300ms de retraso para asegurar que el DOM esté listo
    }
  }
}

export const parseNewlinesToBr = (text) => {
  if (!text) return ''
  return `${text}`?.replace(/\n/g, '<br>')
}

export const isPositiveString = (value) => {
  if (typeof value !== 'string') return false
  const positiveValues = ['true', 'bueno', 'regular']
  return positiveValues.includes(`${value}`.toLowerCase())
}
